@import '~antd/lib/style/themes/default.less';

#login-content-container {
  width: 100%;
  height: 100%;
  max-width: 400px;
  position: relative;
  display: flex;
  align-items: center;

  .login-content {    
    display: flex;
    flex-direction: column;
    min-width: 100%;
    position: absolute;

    .staff-login-options {
      margin-top: @margin-md;
      .staff-email-buttons {
      margin-top: @margin-xs;
        width: 100%;
        display: flex;
        gap: @margin-md;
        > .ant-btn {
          flex: 1;
        }
      }
    }

    .ant-page-header {
      padding: 0;
      margin-bottom: 40px;
      overflow: visible;
    }

    .ant-page-header-heading-left {
      overflow: visible;
      align-items: flex-start;
    }

    .ant-page-header-heading-title {
      color: @title-text-color;
      font-size: @heading-1-size;
      font-weight: @typography-title-font-weight;
      font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;
      white-space: normal;
      line-height: 1.23;
      margin-right: 0;
    }

    // .ant-page-header-heading-title {
    //   color: @title-text-color;
    //   font-size: @heading-1-size;
    //   font-weight: 600;
    //   font-family: sofia-pro, 'Mulish', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;
    //   white-space: normal;
    //   line-height: 1.23;
    //   margin-right: 0;
    //   overflow: visible;
    // }

    .ant-page-header-back-button {
      transform: scale(1.5);
      margin-right: @margin-md;
      margin-top: @margin-md;
    }
  }
}

.ant-form-item {
  padding-bottom: 8px;
}


#new-changes-modal {
  li {
    margin: 0 0 12px 0;
  }
}

@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;