@import '~antd/lib/style/themes/default.less';

.qr-file-upload-container {
  .hide-file-list {
    .ant-upload-list {
      display: none;
    }
  }
  .file-name {
     margin-top: @margin-sm;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;