@import '~antd/lib/style/themes/default.less';

.modal-header {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}
.modal-header img {
  max-width: 48px; /* or any width */
  height: 48px; /* maintain aspect ratio */
  margin-bottom: auto;
}

.label-generator-product-name {
  margin-bottom: @margin-lg;
}

.label-generator-date-selection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.label-generator-date-type {
  margin-right: @margin-sm;
}

.label-generator-date-type-asterisk {
  margin-right: @margin-xs;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.certified-fresh-checkbox {
  flex: 1;
  margin-bottom: @margin-md;
}

.unit-size-selection {
  .ant-form-item-explain {
    color: @warning-color;
  }
  margin-bottom: 0;
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;