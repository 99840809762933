@import '~antd/lib/style/themes/default.less';

#admin-toggle-switch {
  .ant-switch-handle {
    .ant-switch-loading-icon {
      vertical-align: top !important;
    }
  }
}

#users-table-container {
  .user-account-provider {
    display: flex;
    flex-direction: row;
    .user-account-provider-icon {
      > svg, img {
        width: 18px;
      }
    }
    .ant-typography {
      margin-left: @margin-xs;
    }
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;