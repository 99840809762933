@font-face {
  font-family: 'Proxima Nova';
  font-weight: 100;
  src: local('ProximaNova-Thin'), url(./ProximaNova-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 200 300;
  src: local('ProximaNova-Light'), url(./ProximaNova-Light.otf) format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 400;
  src: local('ProximaNova-Regular'), url(./ProximaNova-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 500;
  src: local('ProximaNova-Medium'), url(./ProximaNova-Medium.otf) format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 600;
  src: local('ProximaNova-Semibold'), url(./ProximaNova-Semibold.otf) format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 700;
  src: local('ProximaNova-Bold'), url(./ProximaNova-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 800;
  src: local('ProximaNova-Extrabold'), url(./ProximaNova-Extrabold.otf) format('opentype');
}

@font-face {
  font-family: 'Proxima Nova';
  font-weight: 900;
  src: local('ProximaNova-Black'), url(./ProximaNova-Black.otf) format('opentype');
}


