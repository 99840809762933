@import '~antd/lib/style/themes/default.less';

#connection-status-container {
  display: flex;
  flex-direction: column;
  gap: @margin-xs;

  #connection-status-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: @margin-md;

    #connection-status-label {
      display: flex;
      flex-direction: row;
      column-gap: @margin-sm;
    }
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;