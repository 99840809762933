@import '~antd/lib/style/themes/default.less';

.loading-image-container {
  position: relative;
  display: flex;

  .ant-image {
    width: 100%;
  }

  .image-loading-overlay {
    background-color: white !important;
    .ant-skeleton.ant-skeleton-element {
      height: 100%;
      flex: 1;
      display: flex;
      .ant-skeleton-avatar {
        height: 100%;
        flex: 1;
      }
    }

    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: @image-bg;
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
  }

  .image-loading-overlay.white {
    background-color: white;
    .ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 25%, rgba(200, 200, 200, 0.24) 37%, rgba(255, 255, 255, 0.2) 63%);
      background-size: 400% 100%;
      -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
      animation: ant-skeleton-loading 1.4s ease infinite;
    }
  }
}

.loading-image-container.circle {
  border-radius: 100%;
  overflow: hidden;
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;