$steps: 20;

@keyframes svg-glitch-effect{
  @for $i from 0 through $steps{
    #{percentage($i*(calc(1/$steps)))}{
      clip: rect(random(25) + px, 65px, random(50) + px, 0);
    }
  }
}

.matrix-logo-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  clip: rect(0, 0, 0, 0);
  
  transition: background-color 0.8s ease;
  background-color: white;
  &:hover {
    background-color: black;
  }

  &:hover > .matrix-rain {
    opacity: 100;
  }

  .matrix-rain {
    transition: opacity 0.8s ease;
    opacity: 0;

    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    z-index: 0;
  }

  > svg { 
    position: absolute;
    z-index: 10;
    height: 35px;

    transition: fill 0.8s ease;
    fill: black;
    
    &:nth-child(2),
    &:nth-child(3) {
      visibility: hidden;
      transition: background-color 0.8s ease;
    }
  }

  &:hover {
    > svg {
    fill: lime;
      &:nth-child(2) {
        visibility: visible;
        background-color: black;
        clip: rect(0, 0, 0, 0);
        margin-left: 4px;
        animation: svg-glitch-effect 2s infinite linear alternate-reverse;
      }

      &:nth-child(3) {
        visibility: visible;
        background-color: black;
        clip: rect(0, 0, 0, 0); 
        margin-left: -4px;
        animation: svg-glitch-effect 3s infinite linear alternate-reverse;
      }
    }
  }
}