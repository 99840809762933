@import '~antd/lib/style/themes/default.less';

#monday-sync-status-container {
  display: flex;
  flex-direction: column;
  gap: @margin-xs;

  .avatar-column {
    padding: 0 @padding-md 0 @padding-lg !important;
  }

  .empty-cell {
    color: @disabled-color;
    font-style: italic;
  }

  .has-discrepancies-label {
    color: @red-6;
  }

  .no-discrepancies-label {
    color: @green-7;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;