@import '~antd/lib/style/themes/default.less';

#webstore-shipping-rates-page {
  .shipping-rates-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: @margin-lg;
    gap: @margin-md;
  }

  .shipping-rate-test-address {
    margin-bottom: @margin-lg;
  }
  
  .shipping-rates-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
    gap: @margin-md;
  }

  .add-new-shipping-rate-card {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid @link-color;
    transition: color 0.3s, border 0.3s;
    // TODO: Uncomment when re enabled
    // &:hover {
    //   cursor: pointer;
    //   border-color: @link-hover-color;
    //   .ant-btn-link {
    //     color: @link-hover-color;
    //   }
    // }
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;