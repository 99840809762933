@import '~antd/lib/style/themes/default.less';

#landing-layout-container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: auto;
  position: relative;

  #landing-layout-header {
    width: 100%;
    height: 70px;
  }
  
  #landing-layout-header > img {
    height: 100%;
  }
  
  #landing-layout-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
  
  
    width: 100%;
    height: 100%;
    
    padding: @margin-lg;
  
    @media screen and (min-width: @screen-md) {
      width: 35%;
      height: 100vh;
  
      padding: @margin-lg * 2;
  
      min-width: 500px;
      max-width: 600px;
      box-shadow: ~"0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%)";
      z-index: 2;
    }
  }
  
  #landing-layout-background {
    background-image: url(/assets/bosslevel-bg.png);
    background-color: #000;
    background-position: 50%;
    background-size: cover;
    width: 70%;
    height: 100%;
    flex: 1;
  }
  
  #form-container {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    justify-content: center;
    align-items: center;
  
    padding: 0 @margin-md;
    @media screen and (min-width: @screen-md) {
      padding: 0 @margin-lg * 2;
    }
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;