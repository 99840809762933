@import '~antd/lib/style/themes/default.less';

.hover-help-container {
  
  display: inline;

  a {
    cursor: help;
  }
  
  .hover-help-icon {
    cursor: help;
    margin: 0 @margin-xs;
    transition: color 0.3s;
    color: @link-color;
    &:hover {
      color: @link-hover-color;
    }
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;