@import '~antd/lib/style/themes/default.less';

#reports-page {
  display: flex;
  flex-direction: column;
  gap: @margin-md;
  margin-bottom: @margin-lg;

  .title-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: @padding-xs;

    h4 {
      margin-bottom: 0;
      margin-right: @margin-sm;
      white-space: nowrap;
    }
  }    
  .report-controls {
    display: flex;

    .admin-buttons {
      display: flex;
      margin-left: auto;
      min-width: 20%;
    }

  }
  .ant-card-small {
    width: 100%;
  }

  .report-card{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    min-height: 12rem;
    .card-title-container{
      display: flex;
      .card-admin-buttons{
        display: flex;
        margin-left: auto;
        padding-bottom: 2rem;
      }
      .card-title-text {
        padding-top: 2px;
        max-width: 80%;
        white-space: nowrap;
        overflow:hidden;
        text-overflow: ellipsis;
      }
    }
    .card-body{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 16px;
    }
  }

.reports-grid {
  display: grid;
  gap: @margin-md;

  grid-template-columns: repeat(1, minmax(200px, 1fr));
  @media screen and (min-width: @screen-sm) {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
  }
  @media screen and (min-width: @screen-md) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
  @media screen and (min-width: @screen-lg) {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}

.ant-card{
  height: 100%;
  .ant-card-body{
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: large;
  }
  .ant-card-head{
    align-self: middle;
    margin-top: auto;
    margin-bottom: auto;
  }
  .ant-card-actions{
    display:flex;
  }
}

  .no-reports-message {
    font-size: @font-size-lg;
    font-style: italic;
    margin-bottom: @margin-md;
  }

  .large-collapse {
    .ant-collapse-header {
      display: flex;
      flex-wrap: wrap;
    }
    .panel-title {
      margin-right: auto;
    }
    .ant-collapse-arrow {
      font-size: @font-size-lg;
    }
  }
}

.action-wrapper{
  margin-right: auto;
  align-self: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .card-sign-off{
    font-weight:400;
    margin-top: auto;
    display: inline;
    width: 6rem;
    align-self: middle;
    margin-bottom: auto;
  }
  .action-button{
    margin-left: 1rem;
  }
}

@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;