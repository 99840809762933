@import '~antd/lib/style/themes/default.less';

.shipping-rate-card {
  display: flex;
  flex-direction: column;

  .shipping-rate-switch-icon {
    vertical-align: -0.2em;
  }

  .shipping-rate-card-header {
    margin-bottom: @margin-sm;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .shipping-rate-card-title {
      margin-right: auto;
    }
  }

  > .ant-card-body {
    flex: 1;
  }

  .shipping-rate-description {
    margin-top: 0;
    margin-bottom: @margin-sm;
  }

  .shipping-rate-content-list {
    margin-bottom: 0;
    list-style-type: none;
    padding: 0;

    > li {
      padding: @padding-xss 0;
    }

    .default-price {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }

  .disabled-price {
    li, span {
      text-decoration: line-through;
    }
  }
}

.map-area-modal {
  width: 85vw !important;
  .ant-modal-body {
    padding: 0;
    .map-area-container {
      height: 75vh;
      display: flex;
      // div {
      //   cursor: pointer !important;
      // }
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .map-area-edit-form {
    margin-right: auto;
    display: flex;
    flex-direction: row;
    .ant-form-item {
      margin-bottom: 0;
      padding-bottom: 0;
      margin-right: @margin-md;
    }
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;