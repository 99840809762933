@import '~antd/lib/style/themes/default.less';

.link-card {
  flex: 1;
  user-select: none;
  cursor: pointer;

  .ant-card {
    margin: 0;
    color: @title-text-color;

    &:hover {
      h5.ant-typography {
        color: @link-color;
      }
    }
  }
  
  .ant-card-body {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 124px;
  }

  .card-image {
    width: 50px;
    height: 50px;
  }

  .circle-image {
    border-radius: 100px;
  }

  .card-content {
    margin-left: 20px;
  }

  h5.ant-typography {
    font-weight: 500;
    transition: color 0.3s;
  }

}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;