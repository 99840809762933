@import '~antd/lib/style/themes/default.less';

.offset-ribbon {
  top: 40px;
}

.webstore-product-card {
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s;
  height: 100%;

  .ant-card-cover {
    overflow: hidden;
    border-radius: @border-radius-base @border-radius-base 0 0;
    img {
      max-height: 100px;
      object-fit: contain;
      margin-top: @margin-lg;
      margin-bottom: @margin-sm;
    }
  }

  .ant-card-meta {
    margin-bottom: auto;
  }

  .ant-card-meta-title {
    margin-top: @margin-sm;
    text-overflow: unset;
    white-space: unset;
  }

  .ant-card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: @margin-md;
    padding-top: 0;
    padding-bottom: @margin-lg;

    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }

  .ant-form {
    display: flex;
    flex-direction: column;
    gap: @margin-md;
  }

  .ant-form-item {
    margin-bottom: 0;
    padding-bottom: 0;

    .ant-col.ant-form-item-control {
      overflow: hidden;
    }
  }

  .product-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    gap: @margin-xs;

    .add-to-cart-button {
      flex: 1;
    }
    .product-settings-button {
      flex: 0;
      width: auto;
    }
  }

  .product-size-field {
    max-width: 220px;
  }

  .product-quantity-field {
    width: 60px;
    margin-right: @margin-sm;
  }

  .quantity-available-label {
    font-style: italic;
    white-space: nowrap;
    > span {
      margin: 10px 0 !important;
    }
  }

  .variant-selection-fields {
    display: flex;
    flex-direction: row;
    gap: @margin-md;
  }

  .ant-card-cover {
    opacity: 1;
    transition: opacity 0.3s;
  }
}

.webstore-product-card.disabled {
  background-color: rgba(255, 255, 255, 0.6);
  .ant-card-cover {
    opacity: 0.5;
  }
}

.ant-select-item-option-content {
  overflow: visible;
  text-overflow: unset;
  white-space: nowrap;
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;