@import '~antd/lib/style/themes/default.less';

#beer-bible {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;

  #pagination-container {
    display: flex;
    justify-content: flex-end;
  }

  .ant-empty {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;