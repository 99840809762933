@import '~antd/lib/style/themes/default.less';
.ant-layout-sider {
  z-index: 30;
  height: 100vh;
  max-height: 100vh;
  box-shadow: ~"2px 0 8px 0 rgb(29 35 41 / 5%)";

  flex: 0 0 256px !important;
  max-width: 256px !important;
  min-width: 256px !important;
  width: 256px !important;

  user-select: none;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.side-menu-tag {
  margin: 0 @margin-sm !important;
}

#side-menu {
  .ant-menu:not(.ant-menu-sub):not(.ant-menu-submenu) {
    height: calc(100vh - @header-height - 2px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 0 0 0;
  }

  .ant-menu-inline .ant-menu-item {
    width: 100%;
    user-select: none;
    height: @vertical-tab-height;
    display: flex;
    align-items: center;
  }

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    // Should be same as .ant-menu-inline .ant-menu-item
    width: 100%;
    user-select: none;
    height: @vertical-tab-height;
    display: flex;
    align-items: center;
  }

  .ant-menu-vertical > .ant-menu-item {
    // Should be same as .ant-menu-inline .ant-menu-item
    width: 100%;
    user-select: none;
    height: @vertical-tab-height;
    display: flex;
    align-items: center;

    .anticon {
      display: flex;
      align-items: center;
    }
  }

  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    // Should be same as .ant-menu-inline .ant-menu-item
    width: 100%;
    user-select: none;
    height: @vertical-tab-height;
    display: flex;
    align-items: center;

    .anticon {
      display: flex;
      align-items: center;
    }
  }

  .ant-menu-item-only-child {
    transition: color 0.3s;
  }

  .side-menu-header {
    height: @header-height !important;
    box-shadow: 1px 1px 0 0 #e8e8e8;
    margin: 0 1px 1px 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    // border-radius: 0 0 5px 0;
  }

  .side-menu-header > img {
    height: @header-height * 0.55 !important;
  }

  .side-menu-header > h4.ant-typography {
    color: @title-text-color;
    font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;
    font-weight: 700;
    line-height: 1;
    margin: 0 0 0 20px;
  }
}

@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;