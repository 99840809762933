@import '~antd/lib/style/themes/default.less';

.custom-stepper {
  .steps-content {
    display: flex;
    flex-direction: column;
    margin-top: @margin-md;
    padding: @padding-sm @padding-md;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: @border-radius-base;
    max-height: 50vh;
    overflow-y: auto;
  }
  .steps-content.active {
    // display: default; // TODO: USe default value
  }
  .steps-content.inactive {
    display: none;
  }
}

@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;