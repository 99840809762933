@import '~antd/lib/style/themes/default.less';

.home-card-section {
  display: flex;
  flex-direction: column;
  gap: @margin-sm;
}

.home-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(335px, 1fr));
  gap: @margin-md;
  margin-bottom: @margin-lg;
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;