@import '~antd/lib/style/themes/default.less';

.full-page-message {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding: 0 @padding-sm;
  @media screen and (min-width: @screen-md) {
    padding: 0 (@padding-lg * 2);
  }

  .message-content {
    text-align: center;
    max-width: 500px;
  }

  .message-image {
    width: auto;
    margin-bottom: @margin-lg;
    display: none;
    @media screen and (min-width: @screen-xs) {
      display: block;
    }
  }

  .shadowed-image {
    -webkit-filter: drop-shadow(0px 3px 5px #a3a3a3);
    filter: drop-shadow(0px 3px 5px #a3a3a3);
  }

  h2 {
    color: @text-color;
  }

  // span {
  //   text-align: center;
  //   margin: 0 80px;
  //   max-width: 600px;
  // }

  .return-button {
    margin-top: 20px;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;