@import '~antd/lib/style/themes/default.less';

.review-discrepancies-modal {
  .ant-modal-body {
    padding: 0;
  }

  .ant-table {
    max-height: 80vh;
    overflow-y: auto;
  }

  .ant-table-cell-row-hover {
    background: none !important;
  }

  .value-radio-button {
    pointer-events: auto;
  }

  .image-value-radio-button {
    > span {
      display: flex;
      width: 100%;
    }
  }

  .ant-radio-button-wrapper {
    max-width: 100%;
    height: 100%;
    border-left: 1px solid #d9d9d9;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  .ant-pagination {
    background: #fafafa;
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-right: @padding-md;
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: row;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;