#current-user-avatar {
  .ant-avatar {
    opacity: 1;
    transition: opacity 0.3s;
    cursor: pointer;
  }

  .ant-avatar:hover {
    opacity: 0.8;
  }

  .ant-avatar:active {
    opacity: 1;
  }
}

#name-menu-item {
  cursor: default;
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;