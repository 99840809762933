@import '~antd/lib/style/themes/default.less';

.fixed-button {
  position: fixed !important;
  right: @margin-lg;
  bottom: @margin-lg;
  z-index: 1000;
}

.fixed-button.ant-btn.ant-btn-loading {
  position: fixed;
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;