@import '~antd/lib/style/themes/default.less';

.sticky-header {
  align-self: flex-start;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  top: -1px;
  display: flex;

  transition: margin-left 0.3s, width 0.3s;
  margin-left: 0;
  width: 100%;
}

.sticky-header.hovering {
  margin-left: -@margin-md;
  width: calc(100% + (2 * @margin-md));
  @media screen and (min-width: @screen-sm) {
    margin-left: -@margin-lg;
    width: calc(100% + (2 * @margin-lg));
  }
}

.sticky-header-content {
  flex: 1;
  margin-bottom: @margin-md;
  @media screen and (min-width: @screen-sm) {
    margin-bottom: @margin-lg;
  }
  border-radius: 0 0 @border-radius-base * 2 @border-radius-base * 2;
  box-shadow: none;
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
  transition: box-shadow 0.3s, background-color 0.3s, padding 0.3s;

  .ant-input-group-wrapper.ant-input-search {
    flex: 1;
  }

  .ant-btn {
    flex: 1;
    max-width: 200px;
  }

  display: inline-flex;
  flex-direction: row;
  gap: @margin-md;
  flex-wrap: wrap;
}

.sticky-header.hovering > .sticky-header-content {
  box-shadow: @box-shadow-base;
  padding: @padding-sm;
  background-color: rgba(255, 255, 255, 1);
}


@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;