@import '~antd/lib/style/themes/default.less';

.animated-alert {
  overflow: hidden;
}

.custom-alert {
  .ant-alert {
    padding: @padding-md @padding-lg;
  }

  .ant-alert-icon {
    margin-right: @margin-md;
  }

  .custom-alert-actions {
    .custom-alert-close {
      margin-top: 2px;
      padding-right: 0;
      margin-left: @margin-xs;
      font-size: 12px;
      line-height: 12px;
      transition: color 0.3s;
      color: @text-color-secondary;
      &:hover {
        color: @text-color;
      }
    }
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;