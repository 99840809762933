@import '~antd/lib/style/themes/default.less';

.product-name-link {
  white-space: nowrap;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product-ready-status-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-size: @font-size-sm;

  li {
    display: flex;
    align-items: center;
  }

  .ant-typography {
    flex: 1;
    margin-right: @margin-xs;
    color: inherit;
  }

  .ready {
    color: @success-color;
  }

  .not-ready {
    color: @disabled-color;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;