@import '~antd/lib/style/themes/default.less';

.side-menu-toggle {
  font-size: 20px;
  width: @header-height;
  height: @header-height;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: @white;
  color: @text-color;
  outline: none;
}

.side-menu-toggle:hover {
  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }
}

.site-header {
  height: @header-height;
  padding: 0;
  background: @white;
  box-sizing: border-box;
  position: relative;
  box-shadow: ~"0 1px 4px rgb(0 21 41 / 8%)";
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 20;
}

.site-header-logo-overlay {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.site-header-logo-overlay > img {
  height: @header-height * 0.55;
}

.ant-page-header {
  padding-top: @padding-sm;
  padding-bottom: @padding-sm;
}

.ant-page-header.has-breadcrumb {
  padding-top: 20px;
}

.page-header {
  border-bottom: 1px solid rgb(235, 237, 240);
  background-color: @white;

  .ant-page-header-heading-title {
    overflow: visible;
    text-overflow: clip;
    font-weight: @typography-title-font-weight;
    margin-top: 2px; // Offset due to font size
  
    @media screen and (max-width: @screen-sm) {
      font-size: @heading-5-size;
      line-height: @heading-5-size;
    }
  }

  .ant-page-header-heading-sub-title {
    padding-top: 1px;
    margin-left: 5px;
    overflow: visible;
    white-space: normal;
  
    display: none;
    @media screen and (min-width: @screen-sm) {
      display: inline;
    }
  }
}

.site-header-content {
  flex: 1;
  gap: @margin-md;
  margin-right: @margin-md;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.page-tabs {
  user-select: none;
  .ant-tabs-nav {
    background-color: @white;
    padding: 0 @padding-lg;
    margin: 0;
    border-bottom: 1px solid rgb(235, 237, 240);
  }
  .ant-tabs-content {
    display: none;
  }
}

#page-content {
  width: 100%;
  height: calc(100vh - @header-height);
  max-height: calc(100vh - @header-height);
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
}

#scrollable-page-area-container {
  flex: 1;
  position: relative;
  overflow-y: scroll;
}

#scrollable-page-area {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100%;

  padding: @padding-md;
  @media screen and (min-width: @screen-sm) {
    padding: @padding-lg;
  }
}

main {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

footer {
  padding-top: @padding-lg;
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;