@import '~antd/lib/style/themes/default.less';

.custom-table {
  .table-loading-indicator {
    font-size: 32px;
    margin-top: 55px;
  }

  .ant-table-body {
    min-height: 100%;
  }

  .table-controls {
    display: flex;
    flex-direction: row;
    row-gap: @margin-sm;
    column-gap: @margin-md;
    margin-bottom: @margin-lg;
    flex-wrap: wrap;

    .clear-selection-container {
      white-space: nowrap;
    }

    .selection-count-label {
      margin-right: @margin-lg;
    }
  }

  .table-search {
    min-width: 250px;
    max-width: 350px;
    flex-shrink: 1;
  }

  .ant-table-content {
    overflow-x: auto;
  }

  table {
    .ant-table-cell {
      max-width: 300px;
    }

    .empty-cell {
      color: @disabled-color;
      font-style: italic;
    }
    thead {
      .ant-table-cell {
        white-space: nowrap;
      }
    }

    .centred-column {
      text-align: center;
    }

    .clickable-row {
      cursor: pointer;
    }
    
    .expandable-row {
      cursor: pointer;
    }
    
    .actions-column > div {
      margin-left: auto !important;
    }

    .avatar-column {
      padding: 0 @padding-md 0 @padding-lg !important;
    }

    .disabled-row {
      color: @disabled-color !important;
      background-color: @disabled-bg;
      &:hover {
        background-color: @disabled-bg !important;
      }
    }
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;