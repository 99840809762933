@import '~antd/lib/style/themes/default.less';

footer {

  .footer-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .faded {
    opacity: 0.65;
  }

  #heart-icon {
    transition: color 0.3s, opacity 0.3s;
    margin-left: 5px;
    margin-right: 5px;
    position: relative;
    opacity: 0.65;
    &:hover {
      opacity: 1;
      color: @red-5;
      span:first-child {
        opacity: 1;
      }
    }
    span:first-child {
      color: @red-5;
      transition: color 0.3s, opacity 0.3s;
      position: absolute;
      top: 2px;
      left: 0;
      opacity: 0;
    }
  }

  #version-number {
    position: absolute;
    right: @margin-lg;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;