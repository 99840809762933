@import '~antd/lib/style/themes/default.less';

.upload-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding: @padding-md 0;
  // margin-bottom: @margin-lg * 2;

  min-height: 250px;

  >span {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .ant-image {
    img {
      border-radius: @border-radius-base;
    }
  }
  
  .ant-image-mask {
    border-radius: @border-radius-base;
  }
  .circle-image + .ant-image-mask {
    border-radius: 100%;
  }
  
  .not-applicable-checkbox {
    margin-left: @margin-md;
    margin-bottom: 6px;
  }
  
  .file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ant-upload-list-item-info:hover {
    background-color: transparent;
    transition: none;
  }
  
  .ant-upload-list-item-info {
    margin-top: @margin-md;
  }
  
  .ant-upload {
    padding: @padding-xs;
  }

  .ant-upload.ant-upload-drag {
    flex-grow: 1;
    height: auto;
  }
  
  .ant-upload-drag-container {
    padding: 0;
  }
  
  .upload-icon-drag {
    font-size: 26px;
    margin-bottom: @margin-md;
  }
  
  .upload-message-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 @margin-lg;
  }
  
  .replace-helper-text {
    margin-top: @margin-sm;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;