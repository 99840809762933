@import '~antd/lib/style/themes/default.less';

.inline-link-button {
  text-align: left;
  white-space: normal;
  height: inherit;
  flex: 1;
  margin: 0;
  padding: 0;
  .ant-btn-link {
    margin: 0;
    padding: 0;
  }
}

.unstyled-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.centered-text {
  text-align: center;
}

.no-line-break {
  white-space: nowrap;
}

.field-icon-prefix {
  margin-right: 4px;
}

.horizontal-fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: @margin-md;
}

.modal-description-area {
  display: flex;
  flex-direction: column;
  gap: @margin-sm;
  margin-bottom: @margin-lg;
}

.ant-modal-mask {
  z-index: 1040;
}

.ant-modal-wrap {
  z-index: 1050;
}

.ant-alert-icon {
  margin-right: @margin-md !important;
}

.ant-notification, .ant-message {
  z-index: 2500;
}

// Prevent highlighting text in tabs
.ant-tabs-tab {
  user-select: none;
}

.textOverflow() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
}

.textOverflowMulti(@line: 3, @bg: #fff) {
  position: relative;
  max-height: @line * 1.5em;
  margin-right: -1em;
  padding-right: 1em;
  overflow: hidden;
  line-height: 1.5em;
  text-align: justify;
  &::before {
    position: absolute;
    right: 14px;
    bottom: 0;
    padding: 0 1px;
    background: @bg;
    content: '...';
  }
  &::after {
    position: absolute;
    right: 14px;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
    content: '';
  }
}

// mixins for clearfix
// ------------------------
.clearfix() {
  zoom: 1;
  &::before,
  &::after {
    content: ' ';
    display: table;
  }
  &::after {
    clear: both;
    height: 0;
    font-size: 0;
    visibility: hidden;
  }
}

@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;