@import '~antd/lib/style/themes/default.less';

.auth-list{
    padding-top: 20px;
    margin-bottom: 5px;
    width: 100%;
}

.auth-modal-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .auth-modal-footer-right {
        display: flex;
        gap: 10px;
    }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;