#fresh-club-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    max-height: 100vh;
    padding: 0;
    margin: 0;
}

#fresh-club-header {
    padding-bottom: 50px;
    margin-left: auto;
}

#onetimes-dashboard {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    padding-top: 50px;
    position: absolute;
}

// #onetimes-summary-table{
//     overflow: scroll;
// }

#onetimes-metrics-container{
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;
    gap: 20px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;
}

::-webkit-scrollbar{
    width: 0;
    height: 0;
}

.ant-card{
    width:100%;
}

.ant-card-body{
    font-size: larger;
}

#customer-row{
    display: flex;
    flex-direction: row;
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;