@import '~antd/lib/style/themes/default.less';

#signature-container {
  overflow-x: auto;
  // pointer-events: none;
  // user-select: none;
  transition: background-color 0.3s ease;
}

#signature-container.light {
  background-color: white;
}

#signature-container.dark {
  background-color: #1B1B1B;
  color: white;
}

#email-signature-generator-form {
  .ant-form-item {
    margin-bottom: @margin-xs;
  }

  #custom-pronouns-field {
    margin-top: @margin-xs;
  }

  #cicerone-certification-level-fields {
    padding-left: @padding-lg;
    .ant-form-item-control-input {
      min-height: auto;
    }
  }

  .vertical-checkbox-group {
    width: 100%;
    .ant-checkbox-wrapper {
      display: flex;
      margin-left: 0;
      margin-right: 0;
    }
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;