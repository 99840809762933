@import '~antd/lib/style/themes/default.less';

#email-snippets-container {  
  .row {
    display: flex;
    flex-direction: row;
    gap: @margin-lg;
    position: relative;
    margin-bottom: @margin-lg;
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .preview {
    min-width: 600px;
    display: flex;
    flex-direction: column;
  }

  .code {
    overflow: auto;
  }

  .copy-button {
    width: 180px;
    margin-left: auto;
  }
  
  .email-snippet-code-block {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: auto;
    white-space: pre;
    margin-bottom: 0;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;