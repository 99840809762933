@import '~antd/lib/style/themes/default.less';

.product-card {
  padding: @padding-lg;
  margin-bottom: @margin-lg;

  .columns-container {
    display: flex;
    justify-content: space-between;

    flex-direction: column;
    @media screen and (min-width: @screen-xl) {
      flex-direction: row;
    }
  }

  #left-column {
    margin-right: 0;
    max-width: 800px;
    @media screen and (min-width: @screen-xl) {
      margin-right: 100px;
    }
  }

  #right-column {
    width: 300px;
    max-width: 300px;
    flex-shrink: 0;
  }

  h1.ant-typography {
     margin-bottom: 0;
  }

  h4.ant-typography {
    margin-top: @margin-sm;
    margin-bottom: @margin-lg * 1.5;
    vertical-align: baseline;
  }

  h5.ant-typography {
    font-size: @font-size-base;
    font-weight: 800;
  }

  .info-item {
    margin-top: @margin-lg;
    h5 {
      text-transform: uppercase;
    }
  }

  p {
    margin: 0;
  }

  .ant-tag {
    margin-left: @margin-sm;
  }
  
  .ant-image {
    margin-bottom: @margin-lg;
    img {
      border-radius: 100%;
    }
    .ant-image-mask {
      border-radius: 100%;
    }
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;