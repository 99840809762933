@import '~antd/lib/style/themes/default.less';

.lottie-message-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.generator-output-container {
  display: flex;
  justify-content: center;
  margin: @margin-sm;

  .output-buttons-container {
    display: flex;
    flex-direction: row;
    gap: @margin-md;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;