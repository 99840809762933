#access-denied {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    color: @text-color;
  }
  #back-to-home {
    margin-top: 20px;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;