@import '~antd/lib/style/themes/default.less';

#recent-activity-popover-container {
  display: flex;
  align-items: center;

  #recent-activity-button-container {
    display: flex;
    align-items: center;
    #recent-activity-button {
      border: none;
      box-shadow: none;
    }
  }

  #recent-activity-icon {
    font-size: 22px;
    display: flex;
    justify-content: center;
  }
}

#recent-activity-list {
  max-height: 80vh;
  overflow-y: auto;
}

.recent-activity-overlay {
  padding-top: 0;
  width: 100%;
  max-width: 500px;
  top: @header-height + @margin-xss !important;

  .ant-popover-content {
    .ant-popover-arrow {
      display: none;
    }
  }

  .ant-popover-inner-content {
    padding: 0;

    .ant-divider {
      margin: 0;
    }
  }

  #recent-activity-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: @padding-sm @padding-md;

    h5 {
      margin-bottom: 0;
      margin-left: @margin-xs;
    }

    #recent-activity-close-button {
      border: none;
      box-shadow: none;
    }
  }

  .placeholder-container {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: @padding-lg;
  }

  .skeleton-list-item {
    padding: @padding-lg !important;
  }

  .ant-list-item {
    padding: @padding-md;

    .ant-list-item-meta-title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;

      .event-message-container {
        margin-bottom: @margin-xss;
      }
    }

    .ant-list-item-meta-description {
      font-size: @font-size-sm;
    }

    .ant-collapse-header {
      padding: 0 0 @padding-xss 0 !important;
      font-size: @font-size-sm;

      color: @link-color;
      &:hover {
        color: @link-hover-color;
      }

      .ant-collapse-arrow {
        top: -5px;
        left: 105px;
        padding: @padding-xs 0;
      }

    }

    .ant-collapse-content {
      .ant-collapse-content-box {
        padding-top: @padding-xss;
        padding-bottom: @padding-xs;
        ul {
          list-style-type: disc;
          padding-left: @padding-sm;
        }
      }
    }

    .ant-list-item-action {
      margin-left: @margin-sm;
    }

    .ant-list-item-meta-avatar {
      margin-top: @margin-sm;
      margin-right: @margin-lg;
    }

    .action-badge {
      padding: 4px;
      border-radius: 100px;
      color: white;
    }

    .action-badge.generic {
      background-color: @blue-4;
    }

    .action-badge.create {
      background-color: @green-4;
    }

    .action-badge.update {
      background-color: @blue-4;
    }

    .action-badge.delete {
      background-color: @red-4;
    }
  }  

  #view-more-button-container {
    display: flex;
    justify-content: center;
    padding: @padding-sm @padding-md;
  }
  
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;