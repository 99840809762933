@import '~antd/lib/style/themes/default.less';

.confirm-webstore-request-wrapper {
    .ant-modal {
      padding-bottom: 0;
      .ant-modal-content {
        max-height: 95vh;
      }
    }
}

#shipping-rate-select {
  width: 100%;
}

#confirm-webstore-request-modal-inner-content {

  .ant-input-number {
    width: 100%;
  }

  .ant-form-item {
    margin-bottom: @margin-xs;
  }

  .ant-form-item-row {
    width: 100%;
    > .ant-col-16 {
      max-width: 100%;
    }
  }

  .ant-divider {
    margin: @margin-xs 0 @margin-lg 0;
  }
  
  .steps-action {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .steps-content {
    padding-top: @margin-md;
  }

  #shipping-address-fields {
    opacity: 1;
    transition: opacity 0.3s;
  }

  #shipping-address-fields.disabled {
    opacity: 0.4;
  }

  .hide-form-item-error > .ant-form-item-control > .ant-form-item-explain {
    display: none;
  }

  .sale-total-disclaimer {
    margin-bottom: @margin-sm;
  }

  #shipping-options-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #shipping-options-button {
    margin-bottom: @margin-md;
    margin-left: auto;
    margin-right: auto;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;