@import '~antd/lib/style/themes/default.less';

.customer-contact-select {
  .ant-radio-group {
    margin-bottom: @margin-xs;
  }

  .customer-contact-other {
    display: flex;
    flex-direction: column;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;