@import '~antd/lib/style/themes/default.less';

#ready-status-toggles {
  margin-top: @margin-sm;
  > .ant-form-item {
    margin-bottom: 0;
    .ant-form-item-label {
      min-width: 220px;
      text-align: left;
    }
  }
  margin-bottom: @margin-lg;
}

.save-first-label {
  margin-bottom: @margin-md;
}

.product-editor-form-artist label {
  height: auto;
}

.product-editor-form-format label {
  height: auto;
}

// #product-editor-form {
//   flex: 1;
//   display: flex;

//   // Add bottom padding to all form items...
//   .ant-form-item {
//     margin-bottom: @margin-md;
//   }
//   // ...unless the form item is nested
//   .ant-form-item-control-input-content > .ant-form-item {
//     margin-bottom: 0;
//     padding-bottom: 0;
//   }
// }

// #product-editor-card {
//   flex: 1;
//   display: flex;
//   flex-direction: column;

//   .ant-card-head-title {
//     padding: @padding-md @padding-sm !important;
//     font-weight: 600;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-between;

//     .unnamed-product-title {
//       color: @text-color-secondary;
//       font-style: italic;
//     }
//   }

//   .ant-card-body {
//     padding: 0;
//     flex: 1;
//     display: flex;
//   }

//   .ant-tabs {
//     flex: 1;
//     height: 100%;
//   }

//   .ant-tabs-left > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
//     height: @vertical-tab-height;
//   }

//   .ant-tabs-tab {
//     padding: @padding-xs @padding-lg;
//     margin: @margin-xs 0;
//   }

//   .ant-tabs-content-holder {
//     display: flex;
//   }

//   .ant-tabs-content {
//     flex: 1;
//   }

//   .artwork-thumbnail-image {
//     max-width: 150px;
//     margin-bottom: @margin-lg;
//     margin-right: @margin-lg;
//   }

//   .padded-tab {
//     padding: @margin-lg;
//     position: relative;
//     display: flex;
//   }

//   .ant-tabs-tabpane:not(.padded-tab) {
//     padding-left: 0;
//   }

//   .columns-container {
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     gap: @margin-lg;
//     flex: 1;
//   }

//   .column {
//     display: flex;
//     flex-direction: column;
//     flex: 1;
//     min-width: 250px;
//     max-width: 350px;
//     margin-right: @margin-lg; // TODO: Only apply when more than one column, and only on first column.
//   }

//   .horizontal-field-pair {
//     .ant-form-item-control-input-content {
//       display: flex;
//       gap: @margin-md;
//     }

//     .ant-form-item {
//       flex: 1;
//     }
//   }

//   .half-width {
//     // Substract half flex gap to align with flex fields
//     width: calc(50% - (@margin-md / 2));
//   }

//   .quarter-width {
//     // Substract half flex gap to align with flex fields
//     width: calc(25% - (@margin-md / 2));
//   }

//   #ready-status-toggles {
//     margin-top: @margin-sm;
//     > .ant-form-item {
//       margin-bottom: 0;
//       .ant-form-item-label {
//         min-width: 220px;
//         text-align: left;
//       }
//     }
//     margin-bottom: @margin-lg;
//   }

// }
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;