@import '~antd/lib/style/themes/default.less';

.webstore-product-list {
  // max-height: 100% !important;
  // overflow: auto;
  flex: 1;
  overflow: auto;

  .ant-avatar {
    margin-right: @margin-md;
  }

  .product-total-rrp {
    white-space: nowrap;
  }

  .total-rrp-divider {
    margin: @margin-xs 0;
  }

  .cart-total-rrp {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: @margin-xs;
  }

  .ant-list-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  .compact-list-item {
    padding: @padding-xss @padding-xs;
  }

  .product-details {
    display: flex;
    flex-direction: column;
    margin-right: @margin-md;
    ul {
      padding-left: 18px;
    }
    .variant-sold-out {
      text-decoration: line-through;
      &:after {
        display: inline-block;
        color: @error-color;
        content: '(sold out)';
        margin-left: @margin-xss;
      }
    }
    .variant-partial {
      &:after {
        display: inline-block;
        color: @warning-color;
        content: '(partial supply)';
        margin-left: @margin-xss;
      }
    }
  }

  .product-image {
    width: 50px;
    min-width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: @margin-md;
    img {
      object-fit: contain;
      max-height: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }

  .show-hidden-products-button {
    width: 100%;
  }

  .remove-button {
    border: none; 
    box-shadow: none;
    margin-left: auto;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;