@import '~antd/lib/style/themes/default.less';

.webstore-product-admin-settings {
  li {
    display: flex;
    justify-content: space-between;
    margin-top: @margin-sm;
    &:first-child {
      margin-top: 0;
    }
    .ant-switch {
      margin-left: @margin-xs;
      .ant-switch-loading-icon {
        vertical-align: top !important;
      }
    }
    .ant-button {
      width: 100%;
    }
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;