@import '~antd/lib/style/themes/default.less';

.editor-title-container {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  column-gap: @margin-sm;

  h5 {
    margin-bottom: 0;
  }
}

.modal-error-list {
  color: @error-color;
  max-height: 200px;
  overflow-y: auto;

  padding-top: @padding-xs;
  padding-bottom: @padding-xs;
  border: 1px dashed #e9e9e9;
  border-radius: @border-radius-base;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.editor-layout-form {
  flex: 1;
  display: flex;

  .editor-interaction-blocker {
    position: absolute;
    top: 65px;
    left: 0;
    width: 100%;
    height: calc(100% - 65px);
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.75);
    border-radius: 0 0 @border-radius-base @border-radius-base;
    transition: opacity 0.3s ease;
  }

  .editor-interaction-blocker.active {
    opacity: 1;
    pointer-events: auto;
    cursor: not-allowed;
  }

  .editor-interaction-blocker.inactive {
    opacity: 0;
    pointer-events: none;
    cursor: auto;
  }

  // Add bottom padding to all form items...
  .ant-form-item {
    margin-bottom: @margin-md;
  }
  // ...unless the form item is nested
  .ant-form-item-control-input-content > .ant-form-item {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.autosave-toggle-container {
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: @margin-xs;
}

.save-dropdown-menu {
  display: flex;
  flex-direction: column;
  gap: @margin-md;
  padding: @padding-md @padding-md;
}

.editor-layout-card {
  flex: 1;
  display: flex;
  flex-direction: column;

  .ant-card-head-title {
    padding: @padding-md @padding-sm !important;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: @margin-md;

    .untitled {
      color: @text-color-secondary;
      font-style: italic;
    }

    .controls-container {

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: @margin-md;


      .last-saved {
        color: @text-color-secondary;
      }

      .unsaved-changes {
        color: @orange-6;
      }

      .saving {
        color: @blue-6;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: @margin-sm;
      }

      .saved {
        color: @green-7;
      }

      .error-saving {
        color: @red-6;
      }
    }
  }

  .ant-card-body {
    padding: 0;
    flex: 1;
    display: flex;
  }

  .ant-tabs {
    flex: 1;
    height: 100%;
    position: relative;
  }

  .ant-tabs-left > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
    height: @vertical-tab-height;
  }

  .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab {
    padding: @padding-xs @padding-lg;
    margin: @margin-xs 0;
  }

  .ant-tabs-content-holder {
    display: flex;
  }

  .ant-tabs-content {
    flex: 1;
  }

  .artwork-thumbnail-image {
    max-width: 150px;
    margin-bottom: @margin-lg;
    margin-right: @margin-lg;
  }

  .padded-tab {
    padding: @margin-lg;
    position: relative;
    display: flex;
  }

  .ant-tabs-tabpane:not(.padded-tab) {
    padding-left: 0 !important;
  }

  .columns-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: @margin-lg;
    flex: 1;
  }

  .column {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 250px;
    max-width: 350px;
    margin-right: @margin-lg; // TODO: Only apply when more than one column, and only on first column.
  }

  .horizontal-field-pair {
    .ant-form-item-control-input-content {
      display: flex;
      flex-wrap: wrap;
      gap: @margin-md;
      min-width: 120px;
    }

    .ant-form-item {
      flex: 1;
    }
  }

  .half-width {
    // Substract half flex gap to align with flex fields
    width: calc(50% - (@margin-md / 2));
  }

  .quarter-width {
    // Substract half flex gap to align with flex fields
    width: calc(25% - (@margin-md / 2));
  }

}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;