@import '~antd/lib/style/themes/default.less';

#webstore-requests-page {
  flex: 1;
  display: flex;
  flex-direction: column;

  #cart-header-container {
    display: flex;
    padding: @padding-lg;
  }

  .webstore-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: @margin-md;
    margin-bottom: @margin-lg;
    .ant-ribbon-wrapper {
      height: 100%;
    }
  }

  #no-results {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #pagination-container {
    display: flex;
    justify-content: flex-end;
  }
}

#webstore-product-search {
  min-width: 90px;
}

#cart-content {
  max-height: 60vh;
  position: relative;
  display: flex;
  flex-direction: column;
}

.cart-footer {
  .ant-divider-horizontal {
    margin-top: 0;
    margin-bottom: @margin-md;
  }

  .cart-footer-buttons {
    display: flex;
    flex-direction: row;
    gap: @margin-md;
    justify-content: flex-end;
    margin-bottom: @margin-xss;
  }
}
@typography-title-font-weight: 600;@border-radius-base: 5px;@text-color: rgba(0,0,0,0.65);@title-text-color: rgba(0,0,0,0.80);@header-height: 64px;@vertical-tab-height: 50px;@font-family: 'Proxima Nova', 'Avenir', 'Helvetica Neue', 'Arial', 'Helvetica', sans-serif;@font-size-base: 15px;